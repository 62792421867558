<template lang="pug">
.affiliate-dashboard

  iframe(
    v-if="affiliateDashboardUrl"
    :src="affiliateDashboardUrl"
    frameborder="0"
    allowtransparency
  )
</template>
<script setup lang="ts">
import { useCustomerInfo } from '~/store/customer'

useHead({
  title: 'Dashboard de afiliado'
})

const customerInfo = useCustomerInfo()

const affiliateDashboardUrl = computed(() => customerInfo.affiliateDashboardUrl)
</script>
<style lang="sass" scoped>
.affiliate-dashboard

  iframe
    width: 100%
    height: 2800px
</style>